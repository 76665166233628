
import { useTheme } from './ThemeContext';
function ThemeSwitcher() {
    const { toggleTheme } = useTheme();
    return (
        <>
            <button
                onClick={() => toggleTheme('primary-1')}
                className="md:p-1 flex"
            >
                <span className="w-3 h-3 bg-[#22c55e]"></span>
            </button>
            <button
                onClick={() => toggleTheme('primary-2')}
                className="md:p-1 flex"
            >
                <span className="w-3 h-3 bg-[#eab308]"></span>

            </button>
            <button
                onClick={() => toggleTheme('primary-3')}
                className="md:p-1 flex"
            >
                <span className="w-3 h-3 bg-[#f43f5e]"></span>

            </button>
        </>
    );
}
export default ThemeSwitcher;
