import yoda from '../images/yoda.png';
function Footer() {
  return (
    <>
      <div className="w-full flex min-h-14 h-14 px-10 items-center justify-center">
        <img src={yoda} className="w-10" alt="yoda" />
      </div>
    </>
  );
}

export default Footer;
