import Skills from './Skills';
import Certificates from './Certificates';
import About from './About';
import Works from './Works';
import Line from './Line';
import ThemeSwitcher from './ThemeSwitcher';
import Social from './Social';


function Content() {


  return (
    <>
      <div className="flex flex-wrap w-full md:ml-6 lg:ml-0">
        <div className="flex  w-full lg:w-3/6 border border-primary-2 relative lg:h-full flex  mb-12">
        <div className="flex flex-col w-full h-full">
        <div className="flex lg:hidden">
              <Line />
            </div>
            <div className="border-b border-b-primary-2 min-h-11 h-11 flex items-center px-6 md:pr-5">
              <div className="md:flex hidden w-full">
                <Social />
              </div>
              <div className="flex lg:hidden ml-auto gap-2 md:gap-0 not-hover-opacity">
                <ThemeSwitcher />
              </div>
            </div>
            <div className="flex flex-col md:p-6 p-5 overflow-y-auto lg:h-full">
              <Skills />
              <Certificates />
              <About />
            </div>
            <div className="border-t border-t-primary-2 min-h-11 h-11 flex items-center px-8">
              <h5 className="text-xs text-primary-4  uppercase">website inprogress <span className="text-primary-7">!</span> </h5>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full lg:w-3/6 border border-primary-2 lg:border-l-transparent relative lg:h-full flex flex-col">
          <div className="flex lg:hidden">
            <Line />
          </div>
          <div className="border-b border-b-primary-2 min-h-11 h-11 flex items-center px-6 md:pr-5">
            <h5 className="text-md text-primary-4 uppercase">Work HISTORY • 2016 - now</h5>
            <div className="flex ml-auto gap-2 md:gap-0 not-hover-opacity">
              <ThemeSwitcher />
            </div>
          </div>
          <div className="flex flex-col overflow-y-auto">
            <Works />
          </div>
          <div className="border-t border-t-primary-2 min-h-11 h-11 flex items-center px-8 mt-auto">
            <h5 className="text-xs text-primary-4  uppercase">website inprogress <span className="text-primary-7">!</span> </h5>
          </div>
        </div>
      </div>

    </>
  );
}

export default Content;
