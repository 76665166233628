import Line from './Line';

function Skills() {
  return (
    <>
      <div className="border border-primary-2 mb-5 lg:mb-0 relative">
        <div className="border-b border-b-primary-2 min-h-11 h-11 flex items-center px-6">
          <h5 className="text-md text-primary-4  uppercase">skills:</h5>
          {/* <div className="flex ml-auto">
            <span className="text-xs text-primary-4  uppercase">belive it</span>
          </div> */}
        </div>
        <div className="flex lg:hidden">
          <Line />
        </div>

        <div className="px-5 md:px-6 pt-10 font-normal tracking-wider">
          <div className="flex mb-6 sm:mb-7 xl:mb-8">
            <div className="group cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-14 xl:h-7 xl:min-w-14 xl:min-h-7 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-agile">
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <div className="group-hover:flex hidden absolute bottom-full border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">Agile & scrum</span>
              </div>
            </div>
            <div className="group cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-14 xl:h-7 xl:min-w-14 xl:min-h-7 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-figma">
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <div className="group-hover:flex hidden absolute bottom-full border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">figma</span>
              </div>
            </div>
            <div className="group cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-14 xl:h-7 xl:min-w-14 xl:min-h-7 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-html5">
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <div className="group-hover:flex hidden absolute bottom-full border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">html 5</span>
              </div>
            </div>
            <div className="group cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-14 xl:h-7 xl:min-w-14 xl:min-h-7 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-css3">
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <div className="group-hover:flex hidden absolute bottom-full border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">css 3</span>
              </div>
            </div>
            <div className="group cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-14 xl:h-7 xl:min-w-14 xl:min-h-7 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-scss">
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <div className="group-hover:flex hidden absolute bottom-full border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">scss</span>
              </div>
            </div>
          </div>
          <div className="flex mb-10 xl:ml-9 ml-6 sm:ml-7">
            <div className="group cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-14 xl:h-7 xl:min-w-14 xl:min-h-7 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-tailwindcss">
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <div className="group-hover:flex hidden absolute bottom-full border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">tailwind css</span>
              </div>
            </div>
            <div className="group cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-14 xl:h-7 xl:min-w-14 xl:min-h-7 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-javascript">
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <div className="group-hover:flex hidden absolute bottom-full border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">javascript</span>
              </div>
            </div>
            <div className="group cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-14 xl:h-7 xl:min-w-14 xl:min-h-7 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-reactjs">
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <div className="group-hover:flex hidden absolute bottom-full border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">react js</span>
              </div>
            </div>
            <div className="group cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-14 xl:h-7 xl:min-w-14 xl:min-h-7 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-git">
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <div className="group-hover:flex hidden absolute bottom-full border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">git</span>
              </div>
            </div>
            <div className="group cursor-pointer mx-1 sm:mx-2 relative flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-14 xl:h-7 xl:min-w-14 xl:min-h-7 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-code">
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <span className="absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
              <div className="group-hover:flex hidden absolute bottom-full border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">software development</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Skills;
