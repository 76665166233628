function Social() {
    return (

        <>
            <ul className="flex md:justify-between justify-center text-center w-full font-normal leading-3">
                <li className="relative flex-col flex">
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/giorgi-kishmareia-a0a396109/" className="text-primary-4 h-8 w-8 md:h-auto border border-primary-2 md:border-0 mx-1 md:mx-0 flex items-center justify-center cursor-pointer hover:text-primary-7 icon-linkedin">
                        <span className="hidden">.</span>
                    </a>
                </li>
                <li className="relative flex-col flex">
                    <a target="_blank" rel="noreferrer" href="https://x.com/q1sh101" className="text-primary-4 h-8 w-8 md:h-auto border border-primary-2 md:border-0 mx-1 md:mx-0 flex items-center justify-center cursor-pointer hover:text-primary-7 icon-twitter">
                        <span className="hidden">.</span>
                    </a>
                </li>
                <li className="relative flex-col flex">
                    <a target="_blank" rel="noreferrer" href="https://github.com/q1sh101" className="text-primary-4 h-8 w-8 md:h-auto border border-primary-2 md:border-0 mx-1 md:mx-0 flex items-center justify-center cursor-pointer hover:text-primary-7 icon-github">
                        <span className="hidden">.</span>
                    </a>
                </li>
                <li className="relative flex-col flex">
                    <a target="_blank" rel="noreferrer" href="https://www.upwork.com/freelancers/~01f1827f091b4c2e55" className="text-primary-4 h-8 w-8 md:h-auto border border-primary-2 md:border-0 mx-1 md:mx-0 flex items-center justify-center cursor-pointer hover:text-primary-7 icon-upwork">
                        <span className="hidden">.</span>
                    </a>
                </li>
                <li className="relative flex-col flex">
                    <a target="_blank" rel="noreferrer" href="https://discordapp.com/users/q1sh101" className="text-primary-4 h-8 w-8 md:h-auto border border-primary-2 md:border-0 mx-1 md:mx-0 flex items-center justify-center cursor-pointer hover:text-primary-7 icon-discord">
                        <span className="hidden">.</span>
                    </a>
                </li>
                <li className="relative flex-col flex">
                    <a target="_blank" rel="noreferrer" href="mailto:atomxcodex@outlook.com?subject=Hello!&body=Let's Touch !" className="text-primary-4 h-8 w-8 md:h-auto border border-primary-2 md:border-0 mx-1 md:mx-0 flex items-center justify-center cursor-pointer hover:text-primary-7 icon-mail">
                        <span className="hidden">.</span>
                    </a>
                </li>
            </ul>
        </>
    );
}

export default Social;
