import Eye from './Eye';
function Header() {
  return (
    <>
      <div className="w-full gap-1 flex  min-h-14 h-14 px-10 flex justify-center items-center">
        <Eye />
        <Eye />
      </div>
    </>
  );
}

export default Header;
