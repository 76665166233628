import UserInfo from './UserInfo';
import React, { useState, useEffect } from "react";
import Scanner from './Scanner';
import axios from "axios";
import Line from './Line';
import Social from './Social';

const SideBar = () => {
  const [currentDate, setCurrentDate] = useState({
    time: "",
    date: "",
    day: "",
    month: "",
    year: "",
    browser: "",
  });
  const [uptime, setUptime] = useState(0); // Store uptime in seconds
  const [os, setOs] = useState(""); // Store the operating system

  useEffect(() => {
    const startTime = Date.now(); // Get the start time when the component mounts

    // Function to get current time, date, year, and browser
    const getCurrentInfo = () => {
      const now = new Date();
      const time = getFormattedTime(now); // Get current time in 24-hour format
      const { day, month, date, year } = getFormattedDate(now); // Get day, month, date, and year
      const browser = getBrowser(); // Get browser name
      const osType = getOs(); // Get OS type

      setCurrentDate({
        time,
        date,
        day,
        month,
        year,
        browser,
      });
      setOs(osType); // Set the OS type
    };

    // Function to detect the browser
    const getBrowser = () => {
      const userAgent = navigator.userAgent;
      if (userAgent.indexOf("Chrome") > -1) {
        return "Chrome";
      } else if (userAgent.indexOf("Firefox") > -1) {
        return "Firefox";
      } else if (userAgent.indexOf("Safari") > -1) {
        return "Safari";
      } else if (userAgent.indexOf("Edge") > -1) {
        return "Edge";
      } else {
        return "Unknown";
      }
    };

    // Function to detect the operating system
    const getOs = () => {
      const platform = navigator.platform;
      if (platform.includes("Win")) return "Windows";
      if (platform.includes("Mac")) return "MacOS";
      if (platform.includes("Linux")) return "Linux";
      return "Unknown";
    };

    // Function to format time in 24-hour format
    const getFormattedTime = (date) => {
      const options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }; // 24-hour format
      return date.toLocaleTimeString('en-US', options); // This will give the time in 24-hour format (e.g., 14:45:30)
    };

    // Function to format date and split it into day, month, date, and year
    const getFormattedDate = (date) => {
      const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);
      const [day, month, dayOfMonth, year] = formattedDate.split(' ');

      // Remove the comma after the date, if exists
      const cleanDate = dayOfMonth.replace(',', ''); // remove comma after date if it exists

      return { day, month, date: cleanDate, year };
    };

    // Update info every second
    const interval = setInterval(() => {
      getCurrentInfo();
      setUptime(Math.floor((Date.now() - startTime) / 1000)); // Calculate uptime in seconds
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  // Format uptime into HH:MM:SS format
  const formatUptime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };
  // IP addres atcher
  const [ip, setIP] = useState("");
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    setIP(res.data.ip);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="border border-primary-2 relative mb-12 md:mb-0 lg:border-r-0 md:max-w-72 md:min-w-72 w-full flex flex-col items-center text-center md:items-start md:justify-start md:text-start">
        <div className="flex lg:hidden">
          <Line />
        </div>
        <div className="text-primary-7 flex flex-col w-full">
          <div className="w-full px-3 justify-center md:justify-start border-b border-b-primary-2 min-h-11 h-11 flex items-center w-full">
            <p className="text-xl">{currentDate.time}</p>
          </div>
          <div className="flex uppercase leading-4 justify-between px-3 text-sm w-full border-b border-b-primary-2 min-h-11 h-11 flex items-center w-full">
            <div className="flex">
              <div className="">
                <p className="opacity-75">{currentDate.year}</p>
                <div className="flex">
                  <p className="mr-1">{currentDate.month}</p>
                  <p>{currentDate.date}</p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="">
                <p className="opacity-75">Browser</p>
                <div className="flex">
                  <p>{currentDate.browser}</p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="">
                <p className="opacity-75">Uptime</p>
                <div className="flex">
                  <p>{formatUptime(uptime)}</p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="">
                <p className="opacity-75">Device</p>
                <div className="flex">
                  <p>{os}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full border-b border-b-primary-2">
            <Scanner />
          </div>
          <div className="flex text-min w-full px-3 justify-center md:justify-start border-b border-b-primary-2 min-h-11 h-11 flex items-center w-full">
            <h5 className="text-sm text-primary-7 ">YOUR IP - {ip}</h5>
          </div>
        </div>
        <div className="flex flex-col w-full h-full">
          <div className="px-3 pt-3 flex flex-col  h-full tracking-wider">
            <div className="flex flex-col md:hidden pb-2 w-full">
              <p className="text-sm md:text-xs text-primary-4 mb-2 uppercase">social</p>
              <Social />
            </div>
            <UserInfo />
          </div>
        </div>
      </div>
    </>
  );
};
export default SideBar;
