import SideBar from './SideBar';
import Content from './Content';
import Line from './Line';
// import RightSidebar from './RightSidebar';

function Wrapper() {
    return (
        <>
            <div className="flex flex-col md:flex-row h-full overflow-hidden relative mx-6 xl:mx-10">
                <div className="hidden lg:flex">
                    <Line />
                </div>
                <SideBar />
                <Content />
            </div>
        </>
    );
}

export default Wrapper;