import React from 'react';
import Header from './components/Header';
import Wrapper from './components/Wrapper';
import Footer from './components/Footer';
import { ThemeProvider, } from './components/ThemeContext';

import './App.css';
function App() {
  return (
    <>
      <ThemeProvider>
        <div className="font-display font-bold bg-base lg:h-screen flex flex-col relative before:absolute before:top-0 before:left-0 before:w-full before:h-full before:content-[''] before:opacity-zero-3 before:z-2 before:pointer-events-none  before:bg-[url('./images/static_tv.gif')] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:content-[''] after:opacity-zero-3 after:z-1 after:pointer-events-none after:bg-primary-7">
            <Header />
            <Wrapper />
            <Footer />
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
