import Line from './Line';
function About() {
    return (
        <>
            <div className="border border-primary-2 lg:border-t-0 lg:mb-0 relative h-full">
                <div className="flex lg:hidden">
                    <Line />
                </div>
                <div className="border-b border-b-primary-2 min-h-11 h-11 flex items-center px-6">
                    <h5 className="text-md text-primary-4  uppercase">About Me:</h5>
                    {/* <div className="flex ml-auto">
                        <span className="text-xs text-primary-4  uppercase">read it</span>
                    </div> */}
                </div>
                <div className="flex flex-col p-5 md:p-6  tracking-wider">
                    <p className="text-xs text-primary-7 ">
                        Hey, I’m Giorgi—Earth’s Greatest Programmer, no contest. 🌍
                        <br></br>
                        <br></br>
                        I turn ideas into digital magic, crafting code that dances between simplicity and complexity with effortless grace. Whether it’s building sleek, intuitive designs or solving puzzles only a machine could dream up, I make it look easy—and I love every second of it.
                        <br></br>
                        <br></br>
                        To me, programming isn’t just about writing lines of code; it’s about creating experiences that connect, inspire, and endure. It’s passion, precision, and a little sprinkle of genius.
                        <br></br>
                        <br></br>
                        Simple as that—but nothing short of extraordinary. Let’s create something unforgettable. 🚀</p>
                </div>
            </div>

        </>
    );
}

export default About;
