import Line from './Line';
function Certificates() {
  return (
    <>
      <div className="border border-primary-2 lg:border-t-0 mb-5 lg:mb-0 relative">
        <div className="flex lg:hidden">
          <Line />
        </div>
        <div className="border-b border-b-primary-2 min-h-11 h-11 flex items-center px-6">
          <h5 className="text-md text-primary-4  uppercase">Certificates:</h5>
          {/* <div className="flex ml-auto">
            <span className="text-xs text-primary-4  uppercase">check it</span>
          </div> */}
        </div>
        <ul className="p-5 md:p-6">
          <li className="flex mb-1">
            <a className="group text-xs md:text-sm text-primary-7 hover:text-primary-5" target="_blank" rel="noreferrer" href="https://coursera.org/share/c6d58bca47f2e6882b54792efddae0b1">
              • Meta Front-End Developer Certificate
            </a>
          </li>
          <li className="flex mb-1">
            <a className="group text-xs md:text-sm text-primary-7 hover:text-primary-5" target="_blank" rel="noreferrer" href="https://coursera.org/share/db2ac2933213eba6f0ed1cad3338fb75">
              • Google IT Support Professional Certificate
            </a>
          </li>
          <li className="flex">
            <a className=" group text-xs md:text-sm text-primary-7 hover:text-primary-5" target="_blank" rel="noreferrer" href="https://coursera.org/share/9c623efbe0c3de9fcd4aa1a1089a1a72">
              • Hands-on Introduction to Linux Commands and Shell Scripting
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Certificates;
