import Line from './Line';
function Works() {
    return (
        <>
            <div className="flex flex-col md:p-6 p-5 tracking-wider">
                <div className="p-5 md:p-6 border border-primary-2 relative transition duration-150 group mb-5 md:mb-6">
                    <div className="hidden group-hover:flex">
                        <Line />
                    </div>
                    <div className="flex justify-between items-center mb-1">
                        <h4 className="text-md text-primary-4  uppercase">UI Team Lead</h4>
                        <p className="text-md text-primary-3 ">2020 - 2023 </p>
                    </div>
                    <h5 className="text-sm text-primary-4 mb-1">Digital Area • Part-time</h5>
                    <p className="text-xs text-primary-3 mb-1">REMOTE</p>
                    <p className="text-xs text-primary-3 mb-1">Digital Area, a member of the Bank of Georgia Group PLC, was established in 2018. We have developed a digital ecosystem comprising several integrated platforms to enhance our customer-centric offerings. </p>
                    <div className="flex">
                        <a className="flex text-xs text-primary-7  hover:text-primary-5 text-ellipsis overflow-hidden" target="_blank" rel="noreferrer" href="https://digitalarea.ge/">digitalarea.ge/</a>
                        <span className="text-sm text-bold text-primary-7 mx-2">&</span>
                        <a className="flex text-xs text-primary-7  hover:text-primary-5 text-ellipsis overflow-hidden" target="_blank" rel="noreferrer" href="https://extra.ge/">extra.ge/</a>
                    </div>
                </div>
                <div className="p-5 md:p-6 border border-primary-2 relative transition duration-150 group mb-5 md:mb-6">
                    <div className="hidden group-hover:flex">
                        <Line />
                    </div>
                    <div className="flex justify-between items-center mb-1">
                        <h4 className="text-md text-primary-4  uppercase">UI Team Lead</h4>
                        <p className="text-md text-primary-3 ">2017 - 2022 </p>
                    </div>
                    <h5 className="text-sm text-primary-4 mb-1">Flutter Entertainment • Full-time</h5>
                    <p className="text-xs text-primary-3 mb-1">ON-SIDE</p>
                    <p className="text-xs text-primary-3 mb-1">Adjarabet is Georgian biggest bookmaker for online sports betting and gambling. Visit Adjarabet.com & Adjarabet.am site for sportsbook; poker; online casino & skill games. Powerd by paddy power betfair.
                    </p>
                    <div className="flex">
                        <a className="flex text-xs text-primary-7  hover:text-primary-5 text-ellipsis overflow-hidden" target="_blank" rel="noreferrer" href="https://adjarabet.com/en">adjarabet.com/en</a>
                        <span className="text-sm text-bold text-primary-7 mx-2">&</span>
                        <a className="flex text-xs text-primary-7  hover:text-primary-5 text-ellipsis overflow-hidden" target="_blank" rel="noreferrer" href="https://adjarabet.am/en">adjarabet.am/en</a>
                    </div>
                </div>
                <div className="p-5 md:p-6 border border-primary-2 relative transition duration-150 group mb-5 md:mb-6">
                    <div className="hidden group-hover:flex">
                        <Line />
                    </div>
                    <div className="flex justify-between items-center mb-1">
                        <h4 className="text-md text-primary-4  uppercase">UI developer</h4>
                        <p className="text-md text-primary-3 ">2017 - 2021</p>
                    </div>
                    <h5 className="text-sm text-primary-4 mb-1">Upwork • CHECK PROFILE...</h5>
                    <p className="text-xs text-primary-3 mb-1">REMOTE</p>
                    <p className="text-xs text-primary-3 mb-1">Several different types of projects..</p>
                    <div className="flex">
                        <a className="flex text-xs text-primary-7  hover:text-primary-5 text-ellipsis overflow-hidden" target="_blank" rel="noreferrer" href="https://www.upwork.com/freelancers/~01f1827f091b4c2e55">www.upwork.com/freelancers/~01f1827f091b4c2e55</a>
                    </div>
                </div>
                <div className="p-5 md:p-6 border border-primary-2 relative transition duration-150 group mb-5 md:mb-6">
                    <div className="hidden group-hover:flex">
                        <Line />
                    </div>
                    <div className="flex justify-between items-center mb-1">
                        <h4 className="text-md text-primary-4  uppercase">UI developer</h4>
                        <p className="text-md text-primary-3 ">2018 -  2019 </p>
                    </div>
                    <h5 className="text-sm text-primary-4 mb-1">East European University • Contract</h5>
                    <p className="text-xs text-primary-3 mb-1">REMOTE</p>
                    <p className="text-xs text-primary-3 mb-1">Three management dashboards, for students, lecturers and both.</p>
                    <a className="flex text-xs text-primary-7  hover:text-primary-5 text-ellipsis overflow-hidden" target="_blank" rel="noreferrer" href="https://eeu.edu.ge/?lang=en">eeu.edu.ge/?lang=en</a>
                </div>
                <div className="p-4 border border-primary-2 relative transition duration-150 group">
                    <div className="hidden group-hover:flex">
                        <Line />
                    </div>
                    <div className="flex justify-between items-center mb-1">
                        <h4 className="text-md text-primary-4  uppercase">UI developer</h4>
                        <p className="text-md text-primary-3 "> 2016 -  2017  </p>
                    </div>
                    <h5 className="text-sm text-primary-4 mb-1">Smart Web Studio • Full-time</h5>
                    <p className="text-xs text-primary-3 mb-1">ON-SIDE</p>
                    <p className="text-xs text-primary-3 mb-1">Smart Web - Studio - My works in this studio: about 25-30 websites.</p>
                    <a className="flex text-xs text-primary-7  hover:text-primary-5 text-ellipsis overflow-hidden" target="_blank" rel="noreferrer" href="https://smartweb.ge/en/">smartweb.ge/en/</a>
                </div>
            </div>

        </>
    );
}

export default Works;
