function UserInfo() {
  return (
    <>
      <div>
        <div className="flex flex-col mb-1">
          <p className="text-sm md:text-xs text-primary-4  uppercase">name</p>
          <h1 className="text-lg md:text-md  text-primary-7 uppercase">giorgi kishmareia</h1>
        </div>
        <div className="flex flex-col mb-1">
          <p className="text-sm md:text-xs text-primary-4  uppercase">occupation</p>
          <h5 className="text-lg md:text-md  text-primary-7 uppercase">ui developer</h5>
        </div>
        <div className="flex flex-col mb-1">
          <p className="text-sm md:text-xs text-primary-4  uppercase">corporation</p>
          <h2 className="text-lg md:text-md  text-primary-7 uppercase">theatom.me</h2>
        </div>
        <div className="flex flex-col mb-1">
          <p className="text-sm md:text-xs text-primary-4  uppercase">location</p>
          <h5 className="text-lg md:text-md  text-primary-7 uppercase">New York</h5>
        </div>
      </div>

    </>
  );
}

export default UserInfo;
