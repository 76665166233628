function line() {
    return (
        <>
            <i className="before:absolute before:top-0 before:left-0 before:w-2 before:h-2 before:content-[''] before:border-t before:z-2 before:border-t-primary-7 before:border-l before:border-l-primary-7"></i>
            <i className="before:absolute before:bottom-0 before:left-0 before:w-2 before:h-2 before:content-[''] before:border-b before:z-2 before:border-b-primary-7 before:border-l before:border-l-primary-7"></i>
            <i className="before:absolute before:top-0 before:right-0 before:w-2 before:h-2 before:content-[''] before:border-t before:z-2 before:border-t-primary-7 before:border-r before:border-r-primary-7"></i>
            <i className="before:absolute before:bottom-0 before:right-0 before:w-2 before:h-2 before:content-[''] before:border-r before:z-2 before:border-r-primary-7 before:border-b before:border-b-primary-7"></i>
        </>
    );
}

export default line;
